.button {
  width: 100%;
  height: 3.8rem;
  background-color: #0047b3;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #fff;
  font-size: 1.5rem;
  border-radius: 0.9rem;
  font-family: inherit;

  &:disabled {
    background-color: lighten(#0047b3, 1%);
  }

  & > svg {
    width: 4rem;
  }
}

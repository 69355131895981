@import "../../assets/styles/generic/form.scss";

.form {
  height: 48rem;
  width: 28rem;
  padding: 5rem 2rem 6rem 2rem;
  text-align: center;
}

.formBanner {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #e5e5e5;
  margin-bottom: 4rem;
}

.formHeading {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 1rem;
  text-align: center;

  color: #22215b;
}

.formText {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #22215b;
}

.formContainer {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.formGroup {
  flex: 0 0 16%;
  height: 3rem;
}

.inputGroup {
  margin-bottom: 4rem;
}

.input {
  width: 9rem;
  height: 100%;
  display: block;
  border: 0;
  color: #567df4;
  font-size: 2rem;
  border-bottom: 1px solid #567df4;
  outline: 0;
  padding-bottom: 0.5rem;
  text-align: center;
  font-family: inherit;
}

.button {
  width: 100%;
  display: block;
  height: 3.5rem;
  width: 19.5rem;
  background: #001b6c;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
  outline: 0;
  margin: 0 auto;
  color: #fff;
  font-family: inherit;
  cursor: pointer;

  & > svg {
    position: relative;
    top: 1px;
    width: 4rem;
    height: 3rem;
  }

  &:disabled {
    background: #001b6c9f;
  }
}

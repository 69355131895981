.MuiInput-underline::after {
    border-bottom: 0 !important;
}

.MuiInput-underline::before {
    border-bottom: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
    display: none;
}

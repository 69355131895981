.formContainer {
  width: 42rem;
  // height: -webkit-max-content;
  // height: -moz-max-content;
  // height: max-content;
  padding: 3rem 3rem;
  background: #fff;
  border-radius: 0.5rem;

  @media screen and (max-width: 400px) {
    width: 40rem;
  }
}

.logo {
  display: block;
  width: 11rem;
  height: 4rem;
  margin: 0 auto;
  margin-bottom: 2.5rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 700;
}

.large {
  font-size: 2rem;
}

.default {
  font-size: 1.7rem;
}

.caption {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;
}

.react-daterange-picker__wrapper {
  border: 0 !important;
  padding: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 1px 2px #00000029;
}

.react-daterange-picker__button svg {
  stroke: #707070;
}

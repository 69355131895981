@import "../../../assets/styles/config/breakpoints";
@import "../../../assets/styles/config/mixins";

.container {
  padding-top: 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: #707087;
  font-size: 1.4rem;
  text-align: left;
  position: relative;
  min-width: 1400px;
  min-height: 80vh;

  & > svg {
    position: fixed;
    display: inline-block;
    width: 5rem;
    top: 70%;
    left: 55%;
    // transform: translateX(-80%);

    @include respond-custom(1000px) {
      transform: translateX(-50%);
    }
  }

  @include respond-custom(1000px) {
    // min-width: 100rem;
    margin: 0 2rem;
  }

  @include respond(phone-large) {
    margin: 0 auto;
    // min-width: 96%;
    min-width: 100%;

    padding-top: 1rem;
  }

  @include respond-custom(330px) {
    width: 99%;
    font-size: 1.3rem;
  }

  > .filters {
    background-color: #fff;
    padding: 1rem 0;
    padding-bottom: 7rem;
    height: 7rem;
    text-align: center;

    @include respond(phone-large) {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      padding-bottom: 1.5rem;
      margin-bottom: 3px;
    }

    .filtersContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      @include respond(phone-large) {
        flex-direction: column;
      }

      .dateFilterContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond(phone-large) {
          width: 20rem;
          display: none;
        }

        .dateFilter {
          display: flex;
          flex-direction: column;
        }
      }

      .filterDropDown {
        border: 0;
        height: 3.5rem;
        width: 25rem;
        box-shadow: 0 0.2rem 0.3rem #00000029;
        color: #707087;
        font-size: inherit;
        font-family: inherit;
        padding: 0 0.5rem;
        border-radius: 10px;
        background-color: #fff;

        @include respond(phone-large) {
          width: 20rem;
          box-shadow: 0 1px 2px #00000029;
        }
      }
    }
  }

  > .loaderContainer {
    position: fixed;
    top: 50%;
    left: 53.5%;
    transform: translate(50%, 50%);

    @include respond(phone-large) {
      left: 35%;
      top: 40%;
    }
  }

  > .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    padding: 1.5rem 0.5rem;
    padding-left: 2rem;
    background-color: rgb(201, 201, 201);
    font-weight: bold;
    text-align: left;

    @include respond(phone-large) {
      display: none;
    }

    .status {
      flex: 0 0 3%;
    }

    .date {
      flex: 0 0 18.5%;
      background: orange;
    }

    .amount {
      flex: 0 0 7.5%;
    }

    .type {
      flex: 0 0 6%;
    }

    .prev {
      flex: 0 0 7.5%;
    }

    .current {
      flex: 0 0 7.5%;
    }

    .ref {
      flex: 0 0 18%;
    }

    .address {
      flex: 0 0 21%;
      text-align: center;
    }

    .customer {
      flex: 0 0 21%;
      text-align: center;
    }

    .details {
      flex: 0 0 8%;
    }
  }

  > .log {
    box-shadow: 0.5rem 0.5rem 0.5rem #00000029;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding: 2rem 0.5rem;
    padding-left: 2rem;
    background-color: #fff;
    margin-bottom: 0.2rem;

    &:hover {
      transform: scale(1.02);

      @include respond(phone-large) {
        transform: scale(1);
      }
    }

    @include respond(phone-large) {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      display: initial;
      padding: 0;
      box-shadow: 0.2rem 0.2rem 0.3rem #00000029;
      background-color: transparent;
    }

    .logItem {
      @include respond(phone-large) {
        height: 3.5rem;
        margin-bottom: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        background-color: #fff;

        a {
          text-decoration: none;
          color: #800080;
        }
      }

      > .headingMobile {
        display: none;

        @include respond(phone-large) {
          display: initial;
          flex: 0 0 35%;
        }
      }
    }

    .status {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > .statusColor {
        flex: 0 0 3%;

        span {
          border-radius: 100%;
          width: 1rem;
          height: 1rem;
          display: inline-block;
        }

        .pending {
          background-color: #fe8b0c;
        }

        .failed {
          background-color: #ff0000;
        }

        .success {
          background-color: #3ac962;
        }
      }

      > .statusDate {
        flex: 0 0 12.5%;

        @include respond(phone-large) {
          flex: 0 0 45%;
        }
      }

      > .statusAmount {
        flex: 0 0 7.5%;

        @include respond(phone-large) {
          flex: 0 0 22%;
        }
      }

      > .statusType {
        flex: 0 0 6%;

        @include respond(phone-large) {
          flex: 0 0 18%;
        }
      }

      > .statusRef {
        flex: 0 0 18%;
        word-break: break-all;

        @include respond(phone-large) {
          display: none;
        }
      }

      > .statusCurrent {
        flex: 0 0 7.5%;

        @include respond(phone-large) {
          display: none;
        }
      }

      > .statusPrev {
        flex: 0 0 7.5%;

        @include respond(phone-large) {
          display: none;
        }
      }

      > .statusCustomer {
        flex: 0 0 21%;
        text-align: center;

        @include respond(phone-large) {
          display: none;
        }
      }

      > .statusAccordionToggle {
        display: none;

        > span {
          font: 1.9rem;
          font-weight: bold;
        }

        @include respond(phone-large) {
          display: initial;
          cursor: pointer;
        }
      }

      > a {
        flex: 0 0 8%;
        text-decoration: none;
        color: #800080;

        @include respond(phone-large) {
          display: none;
        }
      }
    }
  }

  > .pagination {
    margin: 2rem auto;
    min-width: 40rem;

    @include respond(phone-large) {
      margin-top: 0.5rem;
      min-width: 30rem;
    }

    span {
      cursor: pointer;
      margin-bottom: 1rem;
      color: #707087;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.3s;
      border: 1px solid #ddd;
      text-align: center;
      background-color: #fff;

      @include respond(phone-large) {
        width: 22.75%;
        padding: 8px 5px;
      }

      &.active {
        background-color: #2a58ae;
        color: white;
        border: 1px solid #2a58ae;
      }

      &:disabled {
        background-color: #2a58ae;
        color: white;
        border: 1px solid #2a58ae;
      }
    }

    > .currentPage {
      width: 9%;
    }
  }
}

.dateRangePicker {
}

.transactions {
  margin-top: 3rem;
  width: 100%;
  color: #22215b;
  padding: 2rem 0.5rem;
  position: relative;
}

.transactionsHeading {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.filter {
  margin-bottom: 2rem;
  overflow: hidden;

  .filterToggle {
    width: 10rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 0.5rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    transition: 0.5s all;
    font-size: 1.4rem;

    img {
      width: 1.1rem;
      line-height: 3rem;
      cursor: pointer;
    }
  }

  .filters {
    transition: 0.3s all;
    opacity: 0;
    max-height: 0;
    transform: translateX(-100%);

    .inputGroup {
      input {
        transition: 0.3s all;
      }

      span {
        position: absolute;
        transition: 0.2s all;

        top: 0.5rem;
        left: 0.5rem;
        opacity: 0;
      }

      input:focus + span,
      input:active + span {
        transform: translateY(-1.3rem);
        font-size: 1.2rem;
        color: #0546be;
        opacity: 1;
      }

      input::-webkit-input-placeholder {
        font-family: "Nunito", sans-serif;
        opacity: 1;
        color: #707070;
      }

      input::-moz-placeholder {
        font-family: "Nunito", sans-serif;
        opacity: 1;
        color: #707070;
      }

      input:-ms-input-placeholder {
        font-family: "Nunito", sans-serif;
        opacity: 1;
        color: #707070;
      }

      input::-ms-input-placeholder {
        font-family: "Nunito", sans-serif;
        opacity: 1;
        color: #707070;
      }

      input::placeholder {
        font-family: "Nunito", sans-serif;
        opacity: 1;
        color: #707070;
      }

      input:placeholder-shown + span {
        opacity: 0;
      }

      .searchTransactions,
      .filterTransactions {
        width: 20rem;
      }
    }

    input,
    select {
      width: 9rem;
      padding: 0 0.5rem;
      border: 0;
      height: 3rem;
      border-bottom: 2px solid rgb(214, 214, 214);
      margin-bottom: 2rem;
      font-family: "Nunito", sans-serif;
      font-size: 1.4rem;
      outline: 0;
      transition: 0.3s all;
      margin-right: 2rem;
    }

    input:hover,
    input:focus,
    select:hover,
    select:focus {
      border-bottom: 2px solid #0846ba;
    }

    ::-webkit-input-placeholder {
      color: black;
      font-family: "Nunito", sans-serif;
      // font-weight: 500;
    }

    ::-moz-placeholder {
      color: black;
      font-family: "Nunito", sans-serif;
      // font-weight: 500;
    }

    :-ms-input-placeholder {
      color: black;
      font-family: "Nunito", sans-serif;
      // font-weight: 500;
    }

    ::-ms-input-placeholder {
      color: black;
      font-family: "Nunito", sans-serif;
      // font-weight: 500;
    }

    ::placeholder {
      color: black;
      font-family: "Nunito", sans-serif;
      // font-weight: 500;
    }
  }

  .isOpen {
    opacity: 1;
    max-height: 700px;
    transform: translateX(0);
  }
}

.customInputGroup {
  span {
    position: relative;
    left: 2rem;
  }

  input {
    width: 15rem !important;
    background-color: red !important;
  }
}

.table {
  font-size: 1.4rem;

  .tableHeading {
    padding: 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    border-radius: 0.5rem;
    font-family: "Avenir";
    background-color: rgb(235, 233, 233);
    margin-bottom: 1rem;

    @include respond(phone-large) {
      display: none;
    }
  }

  .tableBody {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    @include respond(phone-large) {
      width: 100%;
    }

    .tableRow {
      padding: 1.5rem 3rem;
      display: flex;
      justify-content: space-between;
      border-radius: 1rem;
      border: 1px solid #e2e1e1;
      margin-bottom: 0.5rem;

      @include respond(phone-large) {
        display: none;
      }
    }
  }

  .status {
    flex: 0 0 5%;

    .color {
      border-radius: 100%;
      width: 1rem;
      height: 1rem;
      display: inline-block;
    }

    .pending {
      background-color: #fe8b0c;
    }

    .failed {
      background-color: #ff0000;
    }

    .success {
      background-color: #3ac962;
    }
  }

  .date {
    flex: 0 0 13%;
  }

  .amount {
    flex: 0 0 8%;
  }

  .type {
    flex: 0 0 7%;
  }

  .prev {
    flex: 0 0 8%;
  }

  .current {
    flex: 0 0 8%;
  }

  .customer {
    flex: 0 0 20%;
  }

  .address {
    flex: 0 0 10%;
  }

  .ref {
    flex: 0 0 15%;
  }

  .action {
    position: relative;
    flex: 0 0 4%;
    text-align: center;

    input {
      // position: relative;
      display: none;
    }

    .menu {
      width: 1.5rem;
      cursor: pointer;
    }

    .actions {
      position: absolute;
      left: 0;
      z-index: 3;
      background-color: #fff;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      visibility: hidden;
      border-radius: 5px;

      a,
      div {
        cursor: pointer;
        text-decoration: none;
        display: block;
        padding: 0.8rem 1.5rem;
        font-size: 1.2rem;
        color: #303030;

        &:hover {
          background-color: #f0efefe7;
        }
      }

      div {
        display: flex;
        align-items: center;

        & > svg {
          width: 2rem;
          display: inline-block;
          margin-left: 1rem;
        }
      }
    }

    .actionsItemEdit {
      color: #22215b !important;
    }

    input:checked ~ .actions {
      visibility: visible;
    }
  }
}

.exportToExcel {
  position: relative;
  bottom: 5.5rem;
  left: 12rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}

.tableRowMobile {
  display: none;

  @include respond(phone-large) {
    display: initial;
    width: 100%;
  }
}

.tableRowMobileHeading {
  padding: 0 0.8rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.mobileItem {
  padding: 0 0.5rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileItemBodyToggle {
  width: 1rem;
  height: 1rem;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.mobileItemBodyToggleOpen {
  transform: rotate(0deg);
}

.mobileItemBodyToggleClose {
  transform: rotate(-45deg);
}

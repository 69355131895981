@import "./form.module.scss";

.submit {
  grid-column: 2/ -1;
  margin-top: 2rem;

  @include respond(phone) {
    margin-top: 3rem;
  }

  button {
    width: 100%;
    height: 4rem;
    font-size: 1.6rem;
    border-radius: 5px;
    border: 0;
    background-color: #062863;
    color: #eee;
    cursor: pointer;
    outline: none;
  }
}

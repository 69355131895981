@import "../config/mixins";
@import "../config/breakpoints";

.form {
  padding: 2rem;
  overflow: auto;
  background: #fff;
}

.formGroup {
  width: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3rem;

    @include respond-custom(1000px) {
      margin-bottom: 2rem;
    }
  }
}

.formGroupSub {
  display: flex;
  align-items: center;
}

.label {
  font-size: 1.4rem;
  color: #696969;
  margin-bottom: 5px;
  display: inline-block;

  @include respond(phone) {
    margin-bottom: 1px;
  }
}

.select,
.input {
  width: 100%;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  height: 3.5rem;
  font-family: inherit;
  color: #575757;
  letter-spacing: 0;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
}

.select {
  @include respond(phone) {
    min-width: 6rem;
  }
}

.selectionImage {
  width: 15%;
  margin-right: 0.5rem;
  height: 2.5rem;
}

.selectCurrency {
  width: 25%;
  margin-right: 1rem;
}

.loader {
  position: absolute;
  top: 52%;
  left: 1rem;

  & > svg {
    fill: #6d5884;
    width: 4rem;
  }
}

.validationErrorText {
  font-size: 1.2rem;
  color: rgb(209, 58, 58);
  margin-top: 5px;
}

.button {
  width: 100%;
  display: block;
  height: 3.5rem;
  width: 19.5rem;
  background: #001b6c;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
  outline: 0;
  margin: 0 auto;
  color: #fff;
  font-family: inherit;
  cursor: pointer;

  & > svg {
    width: 4rem;
    position: relative;
    top: 3px;
  }

  &:disabled {
    background: #001b6c9f;
  }
}

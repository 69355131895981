// @import-normalize;
@import "./assets/styles/config/variables";

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/AvenirBold.woff2") format("woff2"),
  url("./assets/fonts/avenir/AvenirBold.woff") format("woff");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/AvenirLight.woff2") format("woff2"),
  url("./assets/fonts/avenir/AvenirLight.woff") format("woff");
  font-weight: thin;
  font-style: thin;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/avenir/AvenirMedium.woff2") format("woff2"),
  url("./assets/fonts/avenir/AvenirMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  color: #3d3d3d;
  font-family: "Avenir", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

::-moz-selection {
  background: $cico-blue;
  color: #fff;
}

::selection {
  background: $cico-blue;
  color: #fff;
}

@import "../assets/styles/config/breakpoints";
@import "../assets/styles/config/mixins";

.container {
  padding-top: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.sectionHeading {
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-family: "Nunito", sans-serif;
  color: #22215b;
}

.card {
  width: 38rem;
  height: 13rem;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  @include respond(phone-large) {
    width: 100%;
  }
}

.services {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service {
  text-align: center;
  flex: 0 0 23%;
  padding: 1rem;
  background: rgba(155, 180, 255, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.serviceLogo {
  width: 3rem;
  margin-bottom: 1rem;
}

.serviceText {
  font-size: 1.2rem;
  text-align: center;
  color: #22215b;
}

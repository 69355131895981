// App.scss

.uploadContainer {
  .input,
  .select {
    margin-top: 0.8rem;
    display: block;
    border: 1px solid #c4c4c4;
    padding: 0 1rem;
    font-family: 'Avenir';
    color: #3d3d3d;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    font-size: 1.6rem;
    background: #fff;
  }

  .fileInput {
    padding: 0.6rem 1rem;
  }

  .erroMessage {
    font-size: 13px;
    padding-top: 8px;
    padding-left: 12px;
    color: red;
  }

  & .uploadDetails {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin-top: 10px;

    .actions {
      display: flex;
      align-items: center;

      .fileDetails {
        flex: 0 0 85%;

        .fileName,
        .fileStatus {
          font-size: 12px;
          font-weight: 600;
          white-space: nowrap;
          display: inline-block;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Nunito';

          span {
            color: green;
          }
        }
      }

      .fileAction {
        .removeBtn {
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

    .previewImg {
      width: 150px;
      height: 150px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@import "../assets/styles/config/breakpoints";
@import "../assets/styles/config/mixins";

.container {
  padding-top: 5rem;

  > .sectionContainer {
    height: 40.2rem;
    width: 50.6rem;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    color: #707070;
    font-size: 1.4rem;
    display: flex;
    padding: 0 3rem;
    padding-bottom: 3rem;
    justify-content: space-between;
    flex-direction: column;

    @include respond(phone-large) {
      width: 95%;
      padding: 0 1rem;
    }

    .heading {
      padding: 2rem;
      flex: 0 0 50px;
      text-align: center;
      padding: 2.5rem;

      h3 {
        margin-bottom: 3rem;
        font-size: 1.7rem;
      }

      p {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        line-height: 2.4rem;
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;


      .section {
        flex: 0 0 30%;
        text-align: center;

        img {
          width: 20px;
          margin-bottom: 10px;
        }

        p {
          line-height: 2.4rem;
        }
      }

    }
  }
}

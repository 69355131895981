@import '../assets/styles/config/mixins';
@import '../assets/styles/config/breakpoints';

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .errorCenter {
    margin: 0 20%;
    margin-top: 15%;
    @include respond-custom(700px) {
      margin: 0 10%;
      margin-top: 25%;
    }

    h1 {
      font-size: 9rem;
      font-weight: bolder;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
      font-weight: 500;
    }

    .errorActions {
      margin-top: 5%;
      display: flex;
      justify-content: center;

      > button {
        width: 50%;
      }
    }
  }
}

.group {
  width: 100%;
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 1rem;
    width: 3rem;
  }
}

.default {
  display: initial;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

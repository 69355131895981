//colors
$cico-blue: #2a58ae;
$cico-green: #00b378;
$white: #ffffff;
$content-background-color: #ffffff;
$header-background-color: #ffffff;

//width
$sidebar-width: 20.5rem;

//height
$header-height: 18rem;
$header-height-smaller-devices: 9rem;
$status-bar-height: 4rem;
$status-bar-height-smaller-devices: 4rem;

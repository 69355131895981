@import "../assets/styles/config/breakpoints";
@import "../assets/styles/config/mixins";

.container {
  padding-top: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.sectionHeading {
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-family: "Nunito", sans-serif;
  color: #22215b;
}

.card {
  width: 35rem;
  height: 30rem;
  padding: 1.5rem 2.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-wrap: wrap;

  @include respond(phone-large) {
    width: 100%;
  }
}

.services {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 3rem;
}

.service {
  text-align: center;
  padding: 1rem;
  background: rgba(155, 180, 255, 0.1);
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.serviceLogoContainer {
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  border-radius: 50%;

  &Agent {
    background: rgba(89, 244, 86, 0.2);
  }

  &Consumer {
    background: #c6d4ff;
  }

  &History {
    background: #fff1e1;
  }

  &Repayment {
    background: #ffc6c6;
  }
}

.serviceLogo {
  width: 100%;
  height: 100%;
}

.serviceText {
  font-size: 1.2rem;
  text-align: center;
  color: #22215b;
}

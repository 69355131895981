@import "../../../assets/styles/config/breakpoints";
@import "../../../assets/styles/config/mixins";
@import "../../../assets/styles/config/variables";

.main {
  flex: 1;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  max-width: 100vw;
  display: flex;
  flex-direction: column;

  .contentContainer {
    position: relative;
    left: 0.3rem;
    width: 100%;
    flex: 1;
    min-height: calc(100vh - 7rem);
    background-color: $content-background-color;
    background: #fafafa;
    max-width: 100%;
    overflow: hidden;

    @include respond(phone-large) {
      // padding: 1rem;
      padding: 0.5rem;
      min-height: calc(100vh - 5rem);
      overflow: hidden;
    }
  }
}

.content {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  position: relative;

  @include respond(tab) {
    padding: 0 0.5rem;
  }
}

.maxHeight {
  max-height: calc(100vh - 7rem);
  overflow: hidden;

  @include respond(phone-large) {
    max-height: 100vh;
  }
}

.contentMain {
  overflow: auto;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.back {
  display: inline-block;
  margin: 2rem 0;
  font-size: 1.6rem;
  cursor: pointer;

  & > img {
    width: 2rem;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
}

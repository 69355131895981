@import '../../assets/styles/config/breakpoints';
@import '../../assets/styles/config/mixins';

.container {
  padding: 2rem 0;
  font-size: 1.4rem;
}

.sectionHeading {
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-family: 'Nunito', sans-serif;
  color: #22215b;
}

.card {
  height: 13rem;
  border-radius: 5px;

  @include respond(phone-large) {
    width: 100%;
    overflow-x: auto;
  }
}

.services {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 35rem;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);
  column-gap: 2rem;
  margin-left: 5rem;
}

.service {
  text-align: center;
  flex: 0 0 30%;
  padding: 1rem;
  background: rgba(155, 180, 255, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.serviceLogo {
  width: 3rem;
  margin-bottom: 1rem;
}

.serviceText {
  font-size: 1.2rem;
  text-align: center;
  color: #22215b;
}

.serviceLink {
  text-decoration: none;
}

.form {
  width: 65%;
  padding: 3rem 2.5rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;

  @include respond-custom(1000px) {
    width: 80%;
  }

  @include respond-custom(700px) {
    width: 100%;
  }

  @include respond-custom(500px) {
    grid-template-columns: none;
    grid-gap: 2rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
    width: 100%;
  }
}

.formGroup {
  text-align: left;
}

.label {
  margin-bottom: 1rem;
  color: #3d3d3d;

  font-size: 1.5rem;
}

.input,
.select {
  margin-top: 0.8rem;
  display: block;
  border: 1px solid #c4c4c4;
  padding: 0 1rem;
  font-family: 'Avenir';
  color: #3d3d3d;
  width: 100%;
  height: 4rem;
  border-radius: 5px;
  font-size: 1.6rem;
  background: #fff;
}

.errorText {
  font-size: 1.4rem;
  color: rgb(226, 42, 42);
  margin-top: 0.5rem;
}

.submit {
  width: 100%;
  height: 4rem;
  border-radius: 5px;
  border: 0;
  background-color: #062863;
  color: #eee;
  cursor: pointer;
  outline: none;
  font-size: 1.6rem;

  &:disabled {
    background-color: lighten(#062863, 10%);
  }

  & > svg {
    width: 4rem;
  }

  @include respond(phone) {
    margin-top: 3rem;
  }
}

.button {
  color: #3d3d3d;
}

.docBtn {
  color: #3e215b;
  margin-bottom: 1rem;
  font-size: 3.4rem;
}

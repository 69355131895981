@import "../config/breakpoints";
@import "../config/mixins";

.container {
  width: max-content;
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #fff;
  border-radius: 1rem;
  padding: 4rem 3rem;
  font-size: 1.4rem;

  @include respond(phone-large) {
    width: 40rem;
  }

  @include respond(phone) {
    width: 99%;
  }
}

.back {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 1rem;
  position: absolute;
  top: 2rem;
  left: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backIcon {
  width: 2rem;
  margin-right: 0.7rem;
}

.backText {
  font-size: 1.4rem;
}

.logoContainer {
  width: 11rem;
  height: 5rem;
  margin: 2rem auto;
}

.logo {
  width: 100%;
  height: 100%;
}

.heading {
  min-height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(193, 207, 226, 0.534);
  padding: 1rem;
  border-radius: 0.5rem;
  color: #2666ff;
  margin-bottom: 3rem;
}

.headingIconContainer {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.5rem;
  padding: 0.5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 50%;
}

.headingIcon {
  width: 100%;
  height: 100%;
}

.content {
  margin-bottom: 3rem;
}

.contentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.total {
  font-size: 1.6rem;
  font-weight: 700;
}

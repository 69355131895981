.container {
  padding-top: 4rem;

  > .sectionContainer {
    height: 34.2rem;
    width: 36.6rem;
    margin: 0 auto;
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 3rem;
    background-color: #fff;
    color: #707070;
    font-size: 1.45rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .imageContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 5%;
      margin-bottom: 1rem;

      img {
        position: relative;
        bottom: 2rem;
        width: 4rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    .contentContainer {
      flex: 1;
      margin-top: 2rem;

      .content {
        width: 85%;
        line-height: 25px;
        font-size: 1.4rem;
        margin: 2rem auto 0 auto;
        text-align: center;

        div {
          position: relative;
          top: 12.2rem;
          right: 14rem;

          a {
            text-decoration: none;
            color: #707070;
          }
        }
      }
    }
  }
}

@import "../../assets/styles/config/breakpoints";
@import "../../assets/styles/config/mixins";

.form {
  padding: 3rem 2.5rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;

  @include respond-custom(500px) {
    grid-template-columns: none;
    grid-gap: 2rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
  }
}

.formGroup {
  text-align: left;
}

.label {
  margin-bottom: 1rem;
  color: #3d3d3d;

  font-size: 1.5rem;
}

.input,
.select {
  margin-top: 0.8rem;
  display: block;
  border: 1px solid #c4c4c4;
  padding: 0 1rem;
  font-family: "Avenir";
  color: #3d3d3d;
  width: 100%;
  height: 4rem;
  border-radius: 5px;
  font-size: 1.6rem;
  background: #fff;
}

.errorText {
  font-size: 1.4rem;
  color: rgb(226, 42, 42);
  margin-top: 0.5rem;
}

.submit {
  @include respond(phone) {
    margin-top: 3rem;
  }
}

.button {
  color: #3d3d3d;
}

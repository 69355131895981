@import '../../assets/styles/generic/status.scss';
@import '../../assets/styles/config/breakpoints';
@import '../../assets/styles/config/mixins';

.section {
  margin-block: 2rem;
  padding: 2rem 0;
  width: 50rem;
  height: 56rem;
  background-color: #fff;
  border-radius: 1rem;
  margin-inline: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @include respond(phone-large) {
    width: 95%;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .headingImage {
      width: 85px;
      margin-bottom: 1rem;
      position: initial;
    }

    .headingText {
      margin-block-start: 10px;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .contentContainer {
    margin-block-start: 3rem;
    padding-inline: 4rem;

    .details {
      display: flex;
      justify-content: space-between;
      margin-block-end: 2rem;

      span,
      a {
        font-size: 1.2em;
      }

      .print {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.customerInfo {
  flex: 0 0 55%;
  text-align: right;
}

.message {
  margin-top: 3rem;

  @media print {
    display: none;
  }
}

.messageText {
  text-decoration: none;
  margin-right: 3rem;

  span {
    font-size: 1.2em;
  }
}

.messageIcon {
  margin-right: 1rem;
  width: 3rem;
  vertical-align: middle;
}

.requery {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0.9rem;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid green;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > svg {
    width: 1.5rem;
    margin-left: 1rem;
  }
}
